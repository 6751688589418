<template>
  <default-template>
    <v-layout class="fill-height justify-center align-start background-content">
      <v-responsive
        width="100%"
        :min-height="$vuetify.breakpoint.mobile ? '83vh' : '50vh'"
        max-width="1200"
        class="sub-gift--list overflow-visible"
      >
        <v-card tile elevation="0">
          <v-card-title class="headline d-flex d-sm-block justify-center">
            <b
              v-if="-1 < $route.path.indexOf('/center/notifications')"
              class="py-4 pt-0 pl-sm-4"
              >공지사항</b
            >
            <b
              v-if="-1 < $route.path.indexOf('/center/faqs')"
              class="py-4 pt-0 pl-sm-4"
              >자주묻는질문</b
            >
            <b
              v-if="-1 < $route.path.indexOf('/board/question')"
              class="py-4 pt-0 pl-sm-4"
              >문의하기</b
            >
          </v-card-title>

          <!-- <v-divider/> -->

          <!-- <v-img src="/images/center/center-banner.png" /> -->
          <v-card-text>
            <div class="center-banner-top">
              <h4><span>상품권 구입</span> 및 <span>결제오류</span> 상담은</h4>
              <p>아래 게시판에 문의주시면 빠른 답변드리겠습니다.</p>
              <!-- <v-img src="/images/center/center-banner.png" /> -->
            </div>
            <div class="center-banner-bottom">
              <ul>
                <li class="banner-bottom__red">
                  <p>대표번호</p>
                  <h5>1544-7638</h5>
                </li>
                <li class="banner-bottom__yellow">
                  <p>담당자</p>
                  <h5>010-8483-5405</h5>
                </li>
                <li class="banner-bottom__red">
                  <v-btn
                    :href="'http://pf.kakao.com/_xcxlNNxj'"
                    target="_blank"
                  >
                    <h6>카톡 상담</h6>
                  </v-btn>
                </li>
              </ul>
            </div>
          </v-card-text>

          <v-divider class="mt-4" />
          <!-- <v-card-text>
                        <v-btn-toggle tile class="d-flex">
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/notifications" :color="-1 < $route.path.indexOf('/center/notifications') ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">공지사항</v-btn></v-flex>
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/faqs"          :color="-1 < $route.path.indexOf('/center/faqs')          ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">자주묻는질문</v-btn></v-flex>
                            <v-flex xs4 class="v-btn px-0"><v-btn to="/center/questions"     :color="-1 < $route.path.indexOf('/center/questions')     ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">1:1 상담문의</v-btn></v-flex>
                        </v-btn-toggle>
                    </v-card-text> -->
        </v-card>

        <slot />
      </v-responsive>
    </v-layout>
  </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
  components: {
    DefaultTemplate,
  },
};
</script>

<style scoped>
.v-btn-toggle >>> .v-btn {
  opacity: 1 !important;
}
</style>
